import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import SubscriptionPlan from "../Components/SubscriptionPlan";
import Swal from "sweetalert2";

type Props = {
  msisdn: string;
  unSubscribeUser: () => Promise<void>;
  logoutUser: () => void;
  jwtToken: any;
  setMsisdn: React.Dispatch<React.SetStateAction<string>>;
};

const SubscriptionPlanScreen = (props: Props) => {
  const [packageList, setPackageList] = useState<any>([]);
  const [availablePackages, setAvailablePackages] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalCong, setShowModalCong] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();

  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    navigate("/home");
  };
  const openModalSuccess = () => setShowModalCong(true);
  const closeModalSuccess = () => {
    setShowModalCong(false);
    navigate("/home");
  };
  useEffect(() => {
    if (props.jwtToken) {
      getPackageList();
    }
  }, [props.jwtToken]);

  const getPackageList = async () => {
    try {
      let msisdn = localStorage.getItem("msisdn");
      let endPoint = ApiNames.package_list + "?msisdn=" + msisdn;
      let response = await fetchApiGet(props.jwtToken, endPoint);

      if (response.status === 0) {
        if (
          !response.existingPackages ||
          response.existingPackages.length === 0
        ) {
          removeMsisdn();
          closeModal();
          setIsLoader(false);
          return;
        }

        const existingPackageIds = response.existingPackages.map(
          (pkg: any) => pkg.packageId
        );
        console.log("Existing package IDs:", existingPackageIds);
        setPackageList(existingPackageIds);

        setAvailablePackages(response.availablePackages || []);
        setIsLoader(false);
      } else {
        console.log("Something went wrong with the API response.");
        removeMsisdn();
        closeModal();
        setIsLoader(false);
      }
    } catch (error) {
      setIsLoader(false);
      console.log(
        "Something went wrong while fetching the package list:",
        error
      );
    }
  };

  const onHandleChangePackage = async (index: number, packageType: any) => {
    try {
      let msisdn = localStorage.getItem("msisdn");
      let _data = {
        msisdn: msisdn,
        packageId: index,
        sub_package: packageType,
      };
      let endPoint = ApiNames.changePackageWeb;
      let response = await fetchApiPost(endPoint, props.jwtToken, _data);
      if (response.status === 0) {
        const date = new Date();
        localStorage.setItem("packageChangeTime", date.toString());
        openModalSuccess();
      } else {
        console.log("Something went Wrong");
      }
    } catch (error) {
      console.log("Something went Wrong", error);
    }
  };

  const subscribe = async (index: number, packageType: any) => {
    try {
      let msisdn = localStorage.getItem("msisdn");
      let sessionId = localStorage.getItem("sessionid");
      let _data = {
        msisdn,
        packageId: index,
        sessionId,
      };

      let endPoint = ApiNames.package_subscribe;

      let response = await fetchApiPost(endPoint, props.jwtToken, _data);
      if (
        response.status === 0 ||
        response.result.desc.toLowerCase() === "User Subscribed."
      ) {
        getPackageList();
        Swal.fire("Subscribe", "", "success");
      } else {
        setErrorMsg("Unable to subscribe a user, Please try again");
      }
    } catch (error) {
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const onUnSubscribe = async (packageId: any) => {
    try {
      let msisdn = localStorage.getItem("msisdn");

      let data = {
        msisdn: msisdn,
        packageId,
      };
      let response = await fetchApiPost(
        ApiNames.unsubscribe,
        props.jwtToken,
        data
      );
      if (response.status === 0) {
        if (response.result === "Subscriber UnSubscribed.") {
          getPackageList();
          Swal.fire("UnSubscribe", "", "success");
        }
      }
    } catch (error) {
      console.log("onunsubscribe pressed ::", error);
    }
  };
  const removeMsisdn = () => {
    localStorage.removeItem("msisdn");
    localStorage.removeItem("sessionid");
    props.setMsisdn("");
  };

  return (
    <>
      <SubscriptionPlan
        msisdn={props.msisdn}
        onUnSubscribe={onUnSubscribe}
        logoutUser={props.logoutUser}
        errorMsg={errorMsg}
        jwtToken={props.jwtToken}
        closeModal={closeModal}
        closeModalSuccess={closeModalSuccess}
        onHandleChangePackage={onHandleChangePackage}
        packageList={packageList}
        availablePackages={availablePackages}
        showModal={showModal}
        showModalCong={showModalCong}
        loader={isLoader}
        subscribe={subscribe}
      />
    </>
  );
};

export default SubscriptionPlanScreen;
