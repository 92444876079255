import React from "react";
import logo from "../Assets/img/logo.webp";
import Dropdown from "react-bootstrap/Dropdown";
import profileicon from "../Assets/img/profile-icon.webp";

type Props = {
  onUnSubscribePressed: () => void;
  onSubscribePressed: () => void;
  logoutUser: () => void;
  navigateToSubscriptionPlan: () => void;
};

const Header = (props: Props) => {
  let msisdn = localStorage.getItem("msisdn");
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="navbarWraper">
            <div className="medLogo">
              <img src={logo} width={140} height={60} alt="logo" />
            </div>
            <div className="navLinks">
              <ul>
                <li>
                  {msisdn ? (
                    <></>
                  ) : (
                    <a className="subscribe" onClick={props.onSubscribePressed}>
                      Subscribe
                    </a>
                  )}
                </li>
                {msisdn ? (
                  <>
                    <Dropdown className="header-drop">
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={profileicon} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{ width: "280px" }}>
                        <Dropdown.Item
                          onClick={() => {
                            props.navigateToSubscriptionPlan();
                          }}
                        >
                          Subscription Plans
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            props.logoutUser();
                          }}
                        >
                          Logout
                        </Dropdown.Item>
                        {/* <Dropdown.Item className="unsub">
                          {msisdn ? (
                            <></>
                          ) : (
                            <>
                              <a onClick={props.onSubscribePressed}>
                                Subscribe
                              </a>
                            </>
                          )}
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
