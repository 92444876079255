import { useEffect, useState } from "react";
import { encode } from "base-64";
import { useLocation, useNavigate } from "react-router-dom";
import tagManagerEvents from "../Utils/GoogleTagManager";
import LandingOTP from "../Components/LandingOTP";
import ApiNames from "../Constants/ApiNames";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";

interface ILandingOTPScreen {
  token: string | null;
}
let adNetwork: string = "";
const LandingOTPScreen = (props: ILandingOTPScreen) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const msisdn: string = state?.msisdn;
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  // const [package, setPackage] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [campaignParameter, setCampaignParameter] = useState(
    state.campaignParameter
  );

  useEffect(() => {
    if (msisdn) {
      handleSpecialFlow();
      setSeconds(30);
    } else {
      navigate("/phone");
    }
  }, []);

  useEffect(() => {
    if (props.token) {
      checkUtm();
    }
  }, [props.token]);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
  }, [seconds]);

  const checkUtm = async () => {
    try {
      let response = await fetchApiGet(props.token, ApiNames.utmList);
      if (response.status === 200) {
        {
          response.response.map((e: any) => {
            if (state.utm_source === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      // console.log("UTM failed: " + err);
    }
  };

  const handleSpecialFlow = () => {
    try {
      if (state.isSpecialFlow && state.msisdn && state.otp) {
        if (state.otp.length === 4) {
          let _otp = state.otp.split("");
          setOtp(_otp);
          if (state.milliseconds) {
            setTimeout(() => {
              subscribe(
                state.msisdn,
                state.otp,
                state.isFastFlow
                  ? ApiNames.subscribeFastFlow
                  : ApiNames.subscribe
              );
            }, state.milliseconds);
          } else {
            setTimeout(() => {
              subscribe(
                state.msisdn,
                state.otp,
                state.isFastFlow
                  ? ApiNames.subscribeFastFlow
                  : ApiNames.subscribe
              );
            }, 0);
          }
        }
      }
    } catch (error) {
      console.log("error ::", error);
    }
  };

  const onOTPChange = (index: number, value: string, nextRef: any) => {
    console.log("onOTPChange");
    if (value.trim().length === 1) {
      var numbers = /^[-+]?[0-9]+$/;
      if (value.match(numbers)) {
        let tempOTP = [...otp];
        tempOTP[index] = value;
        setOtp(tempOTP);
        if (nextRef) {
          nextRef.focus();
        }
      }
    } else if (value.trim().length > 1) {
      if (nextRef) {
        nextRef.focus();
      }
    }
  };
  const onRemoveOTP = (index: number, previousRef: any) => {
    console.log("onRemoveOTP");
    let tempOTP = [...otp];
    if (tempOTP[index]) {
      tempOTP[index] = "";
      setOtp(tempOTP);
    } else {
      if (previousRef) {
        previousRef.focus();
      }
    }
  };

  const onSubscribePressed = () => {
    let _otp = otp[0] + otp[1] + otp[2] + otp[3];
    if (_otp.length === 4) {
      setErrorMsg("");
      checkUser(msisdn, _otp);
    } else {
      setErrorMsg("Please enter 4 digits OTP");
    }
  };

  const onResendOTPPressed = (inputRef: any) => {
    setOtp(["", "", "", ""]);
    setErrorMsg("");
    if (inputRef) {
      inputRef.focus();
    }
    sendOTP();
  };

  const sendOTP = async () => {
    try {
      setIsLoading(true);
      let data = {
        msisdn: msisdn,
      };
      let response = await fetchApiPost(ApiNames.sendOtp, props.token, data);
      if (response.status === 0) {
        setIsLoading(false);
        setSeconds(30);
      } else {
        setIsLoading(false);
        setErrorMsg("Unable to send OTP, Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const checkUser = async (_msisdn: string, _otp: string) => {
    try {
      setIsLoading(true);
      let response = await fetchApiGet(
        props.token,
        `${ApiNames.checkUser}/${_msisdn}`
      );
      console.log("response :: ", response.status);
      if (response.status === 0) {
        verifyOtp(_msisdn, _otp);
      } else if (response.status === -1) {
        subscribe(_msisdn, _otp, ApiNames.subscribe);
      } else {
        setIsLoading(false);
        setErrorMsg("Something went wrong, Please try again.");
      }
    } catch (error) {
      console.log("error :: ", error);
      setIsLoading(false);
      setErrorMsg("Something went wrong, Please try again.:");
    }
  };

  const verifyOtp = async (_msisdn: string, _otp: string) => {
    try {
      let data = {
        msisdn: _msisdn,
        otp: _otp,
      };
      let response = await fetchApiPost(ApiNames.verifyOtp, props.token, data);
      if (response.status === 0) {
        moveToHome(_msisdn, response.sessionId);
      } else {
        setIsLoading(false);
        setErrorMsg("Invalid OTP, Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const subscribe = async (
    _msisdn: string,
    _otp: string,
    // package: string,
    endPoint: string
  ) => {
    try {
      let data = {
        msisdn: _msisdn,
        otp: _otp,
        utm_source: state.utm_source,
        packageId:
          state.package.toLowerCase() === "post" ? 5 : state.qsPackageId,
      };

      let response = await fetchApiPost(endPoint, props.token, data);
      if (response.status === 0 || response.status === 1) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("subscribe", "tiktok" + state.qsPackageId);
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("subscribe", "econceptions");
        } else {
          tagManagerEvents("subscribe", state.utm_source);
        }
        moveToHome(_msisdn, response.sessionId);
      } else {
        setIsLoading(false);
        setErrorMsg("Unable to subscribe a user, Please try again");
      }
    } catch (error) {
      console.log("error :: ", error);
      setIsLoading(false);
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const moveToHome = (_msisdn: string, sessionId: string) => {
    let url = window.location.origin;
    let encodedMsisdn = encode(_msisdn);
    url = `${url}/home?user=${encodedMsisdn}&id=${sessionId}`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };

  return (
    <LandingOTP
      isLoading={isLoading}
      otp={otp}
      errorMsg={errorMsg}
      seconds={seconds}
      campaignParameter={campaignParameter}
      onOTPChange={onOTPChange}
      onRemoveOTP={onRemoveOTP}
      onSubscribePressed={onSubscribePressed}
      onResendOTPPressed={onResendOTPPressed}
      package_id={state.qsPackageId}
      pricePoint={state.pricePoint}
    />
  );
};
export default LandingOTPScreen;
