import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import { encode } from "base-64";
import tagManagerEvents from "../Utils/GoogleTagManager";
import Awareness from "../Components/Awareness";

type Props = {
  token: string | null;
};

let adNetwork: string = "";
const url = new URL(window.location.href);
const searchParams = new URLSearchParams(url.search);
let qsPackageId: string | null = searchParams.get("package_id")
  ? searchParams.get("package_id")
  : "2";

const AwarenessScreen = (props: Props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";

  useEffect(() => {
    let pasredPackageId = parseInt(qsPackageId as string);
    if (
      Number.isNaN(pasredPackageId) ||
      pasredPackageId > 4 ||
      pasredPackageId <= 1
    ) {
      qsPackageId = "2";
    }

    if (props.token) {
      checkUtm();
      getFlow();
    }
  }, [props.token]);

  const getFlow = async () => {
    try {
      const endPoint = `${ApiNames.awarenessFlow}?utm_source=${utm_source}&network=jazz&packageId=${qsPackageId}`;
      let response = await fetchApiGet(props.token, endPoint);

      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("subscribe", "tiktok" + qsPackageId);
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("subscribe", "econceptions");
        } else {
          tagManagerEvents("subscribe", utm_source);
        }
        moveToHome(response.msisdn, response.sessionId);
      } else if (response.status === 1) {
        moveToHome(response.msisdn, response.sessionId);
      } else {
        navigateToLanding();
      }
    } catch (error) {
      navigateToLanding();
      console.log("get flow error ::", error);
    }
  };

  const checkUtm = async () => {
    try {
      let response = await fetchApiGet(props.token, ApiNames.utmList);
      if (response.status === 200) {
        {
          response.response.map((e: any) => {
            if (utm_source === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const moveToHome = (_msisdn: string, sessionId: any) => {
    let url = window.location.origin;
    let encodedMsisdn = encode(_msisdn);
    url = `${url}/home?user=${encodedMsisdn} &id=${sessionId}`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };

  const navigateToLanding = () => {
    let url = window.location.origin;
    url = `${url}/landing`;
    window.location.replace(url);
  };
  return <Awareness />;
};

export default AwarenessScreen;
