import React from "react";
import "../StyleSheets/SubscriptionPlan.css";
import subheart from "../Assets/img/sub-heart.webp";
import cardbgdefault from "../Assets/img/card-bg-default.webp";
import warning from "../Assets/Warning.png";
import congration from "../Assets/congraution.png";
import { Modal } from "react-bootstrap";

type Props = {
  msisdn: string;
  onUnSubscribe: (packageID: any) => Promise<void>;
  logoutUser: () => void;
  errorMsg: string;
  jwtToken: string | null;
  closeModal: () => void;
  packageList: number[]; // Updated to match the mapped array from `getPackageList`
  availablePackages: any;
  showModal: boolean;
  showModalCong: boolean;
  closeModalSuccess: () => void;
  onHandleChangePackage: (index: number, packageType: any) => Promise<void>;
  loader: boolean;
  subscribe: (index: number, packageType: any) => Promise<void>;
};

type ProcessProps = {
  showModal: boolean;
  closeModal: () => void;
};

type ConfigurationProps = {
  showModalCong: boolean;
  closeModalSuccess: () => void;
};

const ConfigurationModal = (props: ConfigurationProps) => (
  <>
    <Modal
      show={props.showModalCong}
      onHide={props.closeModalSuccess}
      centered
      className="unsub congrat"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <img src={congration} alt="Success" className="checkimg" />
        <h2>Congratulations</h2>
        <p className="para-text">
          You've successfully purchased a subscription plan.
        </p>
        <button className="btn-continue" onClick={props.closeModalSuccess}>
          Continue
        </button>
      </Modal.Body>
    </Modal>
  </>
);

const ProcessPopUp = (props: ProcessProps) => (
  <>
    <Modal
      show={props.showModal}
      onHide={props.closeModal}
      centered
      className="cs-Modal sp-success unsub"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <img src={warning} alt="Warning" className="checkimg" />
        <p className="para-text">Your request is in process.</p>
      </Modal.Body>
    </Modal>
  </>
);

const SubscriptionPlan = (props: Props) => {
  const isSubscribed = (packageId: number) =>
    props.packageList.includes(packageId);

  return (
    <>
      <ProcessPopUp showModal={props.showModal} closeModal={props.closeModal} />
      <ConfigurationModal
        showModalCong={props.showModalCong}
        closeModalSuccess={props.closeModalSuccess}
      />
      <div className="subscription">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sub-text">
                <div className="sub-title">
                  <span className="text-one">Subscription</span>{" "}
                  <span className="text-two">Plans</span>
                </div>
                <div className="sub-desc">
                  MediQ offers a comprehensive suite of health and wellness
                  services designed to empower you to take charge of your
                  well-being. We provide unique subscription plans to cater to
                  your specific needs:
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-content">
          <div className="container">
            <div className="row">
              {props.availablePackages?.map((data: any, index: number) => (
                <div className="col-xs-12 col-md-3" key={index}>
                  <div
                    className={`d-default ${
                      isSubscribed(data.id) ? "active" : ""
                    }`}
                  >
                    <div className="card-bg">
                      <img src={cardbgdefault} alt="Card Background" />
                    </div>
                    <div className="image">
                      <img src={subheart} alt="Heart Icon" />
                    </div>
                    <div className="card-title">{data.title}</div>
                    <div className="card-des">{data.desc}</div>
                    <div className="card-price">{data.priceText}</div>
                    <div className="card-btn">
                      {isSubscribed(data.id) ? (
                        <button onClick={() => props.onUnSubscribe(data.id)}>
                          Unsubscribe
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            props.subscribe(data.id, data.packageType)
                          }
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlan;
